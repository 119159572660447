import api from "../../../utils/api";
import { GET_ABOUT, GET_TESTIMONIALS } from "./constants";

// Get about
export const getAbout = () => async (dispatch, getState) => {
  try {
    const res = await api.get("/about");

    if (res.data.success) {
      dispatch({
        type: GET_ABOUT,
        payload: res.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Get testimonials
export const getTestimonials = () => async (dispatch, getState) => {
  try {
    const res = await api.get("/about/testimonial");

    if (res.data.success) {
      dispatch({
        type: GET_TESTIMONIALS,
        payload: res.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};
