export const constants = {
  home: {
    en: "Home",
    ar: "الرئيسية",
  },
  contact: {
    en: "Contact",
    ar: "اتصل بنا",
  },
  send: {
    en: "SEND US A MESSAGE",
    ar: "ارسل لنا رسالة",
  },
  name: {
    en: "Your Name",
    ar: "اسمك",
  },
  email: {
    en: "Your Email",
    ar: "البريد الالكتروني",
  },
  phone: {
    en: "Phone",
    ar: "رقم الهاتف",
  },
  subject: {
    en: "Subject",
    ar: "الموضوع",
  },
  message: {
    en: "Your Message",
    ar: "رسالتك",
  },
  sendMsg: {
    en: "Send Message",
    ar: "ارسل رسالة",
  },
  contactInfo: {
    en: "CONTACT INFORMATION",
    ar: "معلومات الاتصال",
  },
};
