export const constants = {
  home: {
    en: "Home",
    ar: "الرئيسية",
  },
  checkout: {
    en: "Checkout",
    ar: "الدفع",
  },
  shippingAddress: {
    en: "Shipping Address",
    ar: "عنوان الشحن",
  },
  firstName: {
    en: "First Name",
    ar: "الاسم الاول",
  },
  enterFirstName: {
    en: "Enter First Name",
    ar: "ادخل الاسم الاول",
  },
  lastName: {
    en: "Last Name",
    ar: "الاسم الاخير",
  },
  enterLastName: {
    en: "Enter Last Name",
    ar: "ادخل الاسم الاخير",
  },
  address: {
    en: "Address",
    ar: "العنوان",
  },
  enterAddress: {
    en: "Enter Address",
    ar: "ادخل العنوان",
  },

  enterStreet: {
    en: "Enter Street",
    ar: "ادخل الشارع",
  },
  zipcode: {
    en: "Zipcode",
    ar: "الرمز البريدي",
  },
  enterZipcode: {
    en: "Enter Zipcode",
    ar: "ادخل الرمز البريدي",
  },
  email: {
    en: "Email",
    ar: "البريد الالكتروني",
  },
  enterEmail: {
    en: "Enter Email",
    ar: "ادخل البريد الالكتروني",
  },
  phone: {
    en: "Phone",
    ar: "الهاتف",
  },
  enterPhone: {
    en: "Enter Phone",
    ar: "ادخل الهاتف",
  },
  yourOrder: {
    en: "Your Order",
    ar: "طلبك",
  },
  totalPrice: {
    en: "Total Price",
    ar: "السعر الكلي",
  },
  placeOrder: {
    en: "Place Order",
    ar: "أضف طلبك",
  },
};
