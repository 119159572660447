import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Footer.css";
import { FaWhatsapp } from "react-icons/fa";
import { AiOutlineInstagram, AiOutlineFacebook } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getCommon } from "../../Redux/Settings/actions";
import { constants } from "./constants";

function Footer() {
  // const dispatch = useDispatch();
  const lng = useSelector((state) => state.settings.lng);
  const common = useSelector((state) => state.settings.common);

  // useEffect(() => {
  //   dispatch(getCommon());
  // }, []);

  return (
    <div className="footer">
      <Container>
        <Row style={{ alignItems: "center" }}>
          <Col md="3">
            <div className="footer_logo">
              <img src="/assets/img/oudlogo.png" />
            </div>
            <p>{lng === "en" ? common.footerDescEn : common.footerDescAr}</p>
            <ul className="footer_social">
              <li>
                <a
                  href={common.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiOutlineFacebook />
                </a>
              </li>
              <li>
                <a
                  href={common.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiOutlineInstagram />
                </a>
              </li>
              <li>
                <a
                  href={common.whatsapp}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaWhatsapp />
                </a>
              </li>
            </ul>
          </Col>
          <Col md="3">
            <h4>{lng === "en" ? constants.quick.en : constants.quick.ar}</h4>
            <ul className="quick_menu_wrap footer_menu_wrap">
              <li>
                <Link to="/">
                  {lng === "en" ? constants.home.en : constants.home.ar}
                </Link>
              </li>
              <li>
                <Link to="/about">
                  {lng === "en" ? constants.about.en : constants.about.ar}
                </Link>
              </li>
              <li>
                <Link to="/contact">
                  {lng === "en" ? constants.contact.en : constants.contact.ar}
                </Link>
              </li>
            </ul>
          </Col>

          <Col md="3">
            <h4>{lng === "en" ? constants.shop.en : constants.shop.ar}</h4>
            <ul className="quick_menu_wrap footer_menu_wrap">
              <li>
                <Link to="/shop/exclusive">
                  {lng === "en"
                    ? constants.exclusives.en
                    : constants.exclusives.ar}
                </Link>
              </li>
              <li>
                <Link to="/shop/perfume">
                  {lng === "en" ? constants.perfumes.en : constants.perfumes.ar}
                </Link>
              </li>
              <li>
                <Link to="/shop/perfume-oil">
                  {lng === "en"
                    ? constants.perfumeOils.en
                    : constants.perfumeOils.ar}
                </Link>
              </li>
              <li>
                <Link to="/shop/home-fragrance">
                  {lng === "en"
                    ? constants.homeFragrance.en
                    : constants.homeFragrance.ar}
                </Link>
              </li>
            </ul>
          </Col>
          <Col md="3">
            <h4>
              {lng === "en" ? constants.contact.en : constants.contact.ar}
            </h4>

            <ul className="footer_contact_det_wrap">
              <li className="footer_contact_det_wrap_item">
                <span>
                  {lng === "en" ? constants.address.en : constants.address.ar}:
                </span>
                <a>{lng === "en" ? common.addressEn : common.addressAr}</a>
              </li>
              <li className="footer_contact_det_wrap_item">
                <span>
                  {lng === "en" ? constants.call.en : constants.call.ar}:
                </span>
                <a>{common.phone}</a>
              </li>
              <li className="footer_contact_det_wrap_item">
                <span>
                  {lng === "en" ? constants.email.en : constants.email.ar}:
                </span>
                <a>{common.email}</a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
