export const constants = {
  home: {
    en: "Home",
    ar: "الرئيسية",
  },
  product: {
    en: "Product",
    ar: "المنتج",
  },
  addCart: {
    en: "Add to Cart",
    ar: "اضف للسلة",
  },
  addedToCart: {
    en: "Added",
    ar: "تمت الاضافة",
  },
  like: {
    en: "YOU MAY ALSO LIKE THIS",
    ar: "قد يكون لديك أيضا يفضل",
  },
};
