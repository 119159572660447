import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  FormControl,
  Button,
  Breadcrumb,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import Pagination from "react-js-pagination";
import "./PerfumeOil.css";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "./constants";
import api from "../../../utils/api";
import { GET_CART } from "../../Redux/Shop/constants";
import { AWS_URL } from "../../../utils/config";

function PerfumeOil() {
  const limit = 12;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lng = useSelector((state) => state.settings.lng);
  const [activePage, setActivePage] = useState(1);
  const [keyword, setkeyword] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);

  const getProducts = async () => {
    const fd = {
      keyword,
      limit,
      page: activePage,
      category: {
        perfumeOil: true,
      },
    };
    const res = await api.post("/product/list", fd);
    if (res.data.success) {
      setProducts(res.data.products);
      setTotalCount(res.data.totalCount);
    }
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const reviewStars = (num) => {
    let stars = [];
    for (let i = 0; i < num; i++) {
      stars.push(<AiFillStar />);
    }

    return <div>{stars}</div>;
  };

  function addProductToCart(item) {
    let products = [];
    if (localStorage.getItem("cart")) {
      products = JSON.parse(localStorage.getItem("cart"));
    }
    products.push({
      _id: item._id,
      nameEn: item.nameEn,
      nameAr: item.nameAr,
      price: item.price,
      hiddenPrice: item.hiddenPrice,
      image: item.image,
      qty: 1,
    });
    localStorage.setItem("cart", JSON.stringify(products));
    setCart(products);
    setCartInReduxAndState();
  }

  const findProductExistInCart = (id) => {
    if (cart) {
      const findProduct = cart.find((product) => product._id === id);

      if (findProduct) {
        return true;
      } else {
        return false;
      }
    }
  };

  const setCartInReduxAndState = () => {
    let cartItems = localStorage.getItem("cart");
    setCart(JSON.parse(cartItems));
    dispatch({
      type: GET_CART,
      payload: JSON.parse(cartItems),
    });
  };

  useEffect(() => {
    getProducts();
  }, [activePage]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getProducts();
    setCartInReduxAndState(cart);
  }, []);

  return (
    <div>
      <Container>
        <div className="shop_head">
          <Breadcrumb>
            <Breadcrumb.Item href="" onClick={() => navigate("/")}>
              {lng === "en" ? constants.home.en : constants.home.ar}
            </Breadcrumb.Item>

            <Breadcrumb.Item active>
              {lng === "en" ? constants.shop.en : constants.shop.ar}
            </Breadcrumb.Item>
          </Breadcrumb>
          <h3>
            {lng === "en" ? constants.perfumeOil.en : constants.perfumeOil.ar}
          </h3>
        </div>
        <div className="shop_top_control">
          <div className="shop_top_control_wrap">
            <ul className="shop_top_control_edit">
              <li className="shop_top_control_sort">
                {/* <Form.Group className="shop_top_control_sort_child">
                  <Form.Label>Sort</Form.Label>
                  <Form.Select>
                    <option>Low to High</option>
                    <option>High to Low</option>
                  </Form.Select>
                </Form.Group> */}
              </li>
            </ul>
            <div className="shop_top_control_search d-flex">
              <FormControl
                type="search"
                placeholder={
                  lng === "en"
                    ? constants.searchProduct.en
                    : constants.searchProduct.ar
                }
                className="me-2"
                onChange={(e) => setkeyword(e.target.value)}
              />
              <Button
                className="shop_top_control_search_btn"
                onClick={() => getProducts()}
              >
                {lng === "en" ? constants.search.en : constants.search.ar}
              </Button>
            </div>
          </div>
        </div>
        <div className="shop_items_wrapper">
          <Row>
            {products.map((product) => (
              <Col md="3" sm="6" xs="6">
                <div className="shop_item_card">
                  <div className="shop_item_card_img">
                    <Link to={`/product/${product._id}`}>
                      <img
                        src={AWS_URL + product.image.image}
                        alt={product.nameEn}
                      />
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={`/product/${product._id}`}
                      className="shop_prod_title"
                    >
                      {" "}
                      <h4>
                        {lng === "en" ? product.nameEn : product.nameAr}
                      </h4>{" "}
                    </Link>
                    <div className="shop_prod_rating">
                      {reviewStars(product.star)}
                    </div>
                    <div className="shop_prod_price">
                      <p className="shop_prod_original_price">
                        {product.hiddenPrice} AED
                      </p>
                      <p className="shop_prod_offer_price">
                        {product.price} AED
                      </p>
                    </div>
                    <Button
                      className="shop_prod_add_to_cart_btn"
                      onClick={() => addProductToCart(product)}
                      disabled={findProductExistInCart(product._id)}
                    >
                      {/* {lng === "en"
                        ? constants.addCart.en
                        : constants.addCart.ar} */}
                      {findProductExistInCart(product._id)
                        ? lng === "en"
                          ? constants.addedToCart.en
                          : constants.addedToCart.ar
                        : lng === "en"
                        ? constants.addCart.en
                        : constants.addCart.ar}
                    </Button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
        <div className="shop_pagination">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={limit}
            totalItemsCount={totalCount}
            pageRangeDisplayed={5}
            onChange={(page) => handlePageChange(page)}
            prevPageText={<IoIosArrowBack />}
            nextPageText={<IoIosArrowForward />}
          />
        </div>
      </Container>
    </div>
  );
}

export default PerfumeOil;
