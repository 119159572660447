import { GET_ABOUT, GET_TESTIMONIALS } from "./constants";

const initialState = {
  loading: false,
  about: {},
  testimonials: [],
};

export default function about(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ABOUT:
      return {
        ...state,
        loading: false,
        about: payload.data,
      };

    case GET_TESTIMONIALS:
      return {
        ...state,
        loading: false,
        testimonials: payload.data,
      };

    default:
      return state;
  }
}
