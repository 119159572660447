import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AWS_URL } from "../../utils/config";
import { getHomeOff } from "../Redux/Home/actions";

const HomeOff = () => {
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.settings.lng);
  const homeOff = useSelector((state) => state.home.homeOff);

  useEffect(() => {
    dispatch(getHomeOff());
  }, []);

  return (
    <>
      <div className="hom_full_ad">
        <div className="hom_full_ad_img">
          <Link to={homeOff.link ? homeOff.link : ""}>
            <img
              src={
                lng === "en"
                  ? AWS_URL + homeOff?.imageEn?.image
                  : AWS_URL + homeOff?.imageAr?.image
              }
              alt="Offer banner"
            />
          </Link>
        </div>
      </div>
    </>
  );
};

export default HomeOff;
