export const constants = {
  message: {
    en: "Congratulation! Your order has been processed",
    ar: "شكراً لك! تم تنفيذ طلبك",
  },
  orderNumber: {
    en: "Order number",
    ar: "رقم الطلب",
  },
  date: {
    en: "Date",
    ar: "التاريخ",
  },
  phone: {
    en: "Phone",
    ar: "الهاتف",
  },
  email: {
    en: "Email",
    ar: "البريد الإلكتروني",
  },
  total: {
    en: "Total",
    ar: "المجموع",
  },
  payWithCash: {
    en: "Pay with cash upon delivery",
    ar: "الدفع بالنقد عند التوصيل",
  },
  home: {
    en: "Back to Home",
    ar: "العودة للصفحة الرئيسية",
  },
};
