import React, { useEffect } from "react";
import { Col, Container, Row, Breadcrumb } from "react-bootstrap";
import { MdOutlineDeliveryDining } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { BsArrowCounterclockwise } from "react-icons/bs";
import { FiTarget } from "react-icons/fi";
import { AiOutlineEye, AiOutlineHeart } from "react-icons/ai";
import abtimg from "../../../assets/img/oud_abt.jpg";

import "./About.css";
import Testimonial from "../testimonial/Testimonial";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "./constants";
import { getAbout, getTestimonials } from "../../Redux/About/actions";
import { AWS_URL } from "../../../utils/config";

function About() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.settings.lng);
  const about = useSelector((state) => state.about.about);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAbout());
    dispatch(getTestimonials());
  }, []);

  return (
    <>
      {about && (
        <Container>
          <div className="cart_head">
            <Breadcrumb>
              <Breadcrumb.Item href="" onClick={() => navigate("/")}>
                {lng === "en" ? constants.home.en : constants.home.ar}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {lng === "en" ? constants.about.en : constants.about.ar}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="about_wrapper">
            <Row>
              <Col md="6">
                <div className="about_img">
                  <img src={AWS_URL + about?.image?.image} alt="about" />
                </div>
              </Col>
              <Col md="6">
                <div className="about_content">
                  <h2>
                    {lng === "en" ? constants.aboutUs.en : constants.aboutUs.ar}
                  </h2>
                  <p>{lng === "en" ? about.descEn : about.descAr}</p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="about_feature_wrap">
            <Row>
              <Col md="4">
                <div className="about_features">
                  <div className="about_features_icon">
                    <MdOutlineDeliveryDining />
                  </div>
                  <h5>
                    {lng === "en"
                      ? constants.delivery.en
                      : constants.delivery.ar}
                  </h5>
                  <p>{lng === "en" ? about.deliveryEn : about.deliveryAr}</p>
                </div>
              </Col>
              <Col md="4">
                <div className="about_features">
                  <div className="about_features_icon">
                    <BsArrowCounterclockwise />
                  </div>
                  <h5>
                    {lng === "en" ? constants.money.en : constants.money.ar}{" "}
                  </h5>
                  <p>{lng === "en" ? about.moneyEn : about.moneyAr}</p>
                </div>
              </Col>
              <Col md="4">
                <div className="about_features">
                  <div className="about_features_icon">
                    <BiSupport />
                  </div>
                  <h5>
                    {" "}
                    {lng === "en"
                      ? constants.support.en
                      : constants.support.ar}{" "}
                  </h5>
                  <p>{lng === "en" ? about.supportEn : about.supportAr}</p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="about_our_vis_mis_wrap">
            <Row>
              <Col md="4">
                <div className="our_vis_mis">
                  <FiTarget />

                  <h4 className="hom_sec_title">
                    {lng === "en" ? constants.mission.en : constants.mission.ar}
                  </h4>
                  <p>{lng === "en" ? about.missionEn : about.missionAr}</p>
                </div>
              </Col>
              <Col md="4">
                <div className="our_vis_mis">
                  <AiOutlineEye />
                  <h4 className="hom_sec_title">
                    {lng === "en" ? constants.vision.en : constants.vision.ar}
                  </h4>
                  <p>{lng === "en" ? about.visionEn : about.visionAr}</p>
                </div>
              </Col>
              <Col md="4">
                <div className="our_vis_mis">
                  <AiOutlineHeart />
                  <h4 className="hom_sec_title">
                    {lng === "en" ? constants.value.en : constants.value.ar}
                  </h4>
                  <p>{lng === "en" ? about.valueEn : about.valueAr}</p>
                </div>
              </Col>
            </Row>
          </div>

          <h3 className="hom_sec_title">
            {lng === "en" ? constants.testimonial.en : constants.testimonial.ar}
          </h3>

          <Testimonial />
        </Container>
      )}
    </>
  );
}

export default About;
