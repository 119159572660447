export const constants = {
  instaHead: {
    en: "Instagram Feed",
    ar: "حسابات الإنستجرام",
  },
  feturedHead: {
    en: "Featured Products",
    ar: "المنتجات المميزة",
  },
};
