export const constants = {
  home: {
    en: "Home",
    ar: "الرئيسية",
  },
  shop: {
    en: "Shop",
    ar: "المتجر",
  },
  perfume: {
    en: "Perfume Products",
    ar: "منتجات الرئيسية",
  },
  search: {
    en: "Search",
    ar: "بحث",
  },
  searchProduct: {
    en: "Search products",
    ar: "بحث عن منتجات",
  },
  addCart: {
    en: "Add to Cart",
    ar: "اضف للسلة",
  },
  addedToCart: {
    en: "Added",
    ar: "تمت الاضافة",
  },
};
