export const constants = {
  home: {
    en: "Home",
    ar: "الرئيسية",
  },
  about: {
    en: "About",
    ar: "عنا",
  },
  aboutUs: {
    en: "About Us",
    ar: "عنا",
  },
  delivery: {
    en: "Delivery",
    ar: "توصيل",
  },
  money: {
    en: "Money Guarantee",
    ar: "ضمان المال",
  },
  support: {
    en: "Support",
    ar: "دعم",
  },
  mission: {
    en: "Our Mission",
    ar: "مهمتنا",
  },
  vision: {
    en: "Our Vision",
    ar: "رؤيتنا",
  },
  value: {
    en: "Our Value",
    ar: "قيمنا",
  },
  testimonial: {
    en: "Testimonials",
    ar: "التأشيرات",
  },
};
