import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getHomeDeals } from "../Redux/Home/actions";
import { AWS_URL } from "../../utils/config";
import { Link } from "react-router-dom";

const HomeDeal = () => {
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.settings.lng);
  const homeDeals = useSelector((state) => state.home.homeDeals);

  useEffect(() => {
    dispatch(getHomeDeals());
  }, []);

  return (
    <>
      {homeDeals.length > 0 && (
        <Container>
          <Row>
            <Col md="5">
              <div className="hom_sec_left_ad_wrap hom_sec_ad_wrap">
                <div className="hom_sec_left_ad_wrap_content">
                  <Link to={homeDeals[0].link ? homeDeals[0].link : ""}>
                    <img
                      src={
                        lng === "en"
                          ? AWS_URL + homeDeals[0].imageEn.image
                          : AWS_URL + homeDeals[0].imageAr.image
                      }
                      alt={homeDeals[0].title}
                    />
                  </Link>
                </div>
              </div>
            </Col>
            <Col md="7">
              <div className="hom_sec_right_ad_wrap hom_sec_ad_wrap">
                <div className="hom_sec_right_ad_wrap_content">
                  <Link to={homeDeals[1].link ? homeDeals[1]?.link : ""}>
                    <img
                      src={
                        lng === "en"
                          ? AWS_URL + homeDeals[1].imageEn.image
                          : AWS_URL + homeDeals[1].imageAr.image
                      }
                      alt={homeDeals[1].title}
                    />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default HomeDeal;
