import { GET_CART, GET_EXCLUSIVE } from "./constants";

const initialState = {
  loading: false,
  exclusiveProducts: [],
  cart: [],
};

export default function shop(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_EXCLUSIVE:
      return {
        ...state,
        loading: false,
        exclusiveProducts: payload.data,
      };

    case GET_CART:
      return {
        ...state,
        loading: false,
        cart: payload,
      };

    default:
      return state;
  }
}
