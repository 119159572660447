import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Breadcrumb, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import api from "../../utils/api";
import { AWS_URL } from "../../utils/config";
import { GET_CART } from "../Redux/Shop/constants";
import "./Checkout.css";
import { constants } from "./constants";

function Checkout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lng = useSelector((state) => state.settings.lng);
  const [cart, setCart] = useState([]);
  const [payAmount, setPayAmount] = useState(0);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    street: "",
    zipcode: "",
  });

  const setCartInReduxAndState = () => {
    let cartItems = localStorage.getItem("cart");
    setCart(JSON.parse(cartItems));
    dispatch({
      type: GET_CART,
      payload: JSON.parse(cartItems),
    });
  };

  const getPayAmount = () => {
    let amount = 0;
    if (cart) {
      cart.map((item) => {
        amount = amount + item.price * item.qty;
      });
    }
    setPayAmount(amount);
  };

  const placeOrder = async () => {
    let fd = {
      firstName: data.firstName,
      lastName: data.lastName,
      address: data.address + " " + data.street,
      zipcode: data.zipcode,
      email: data.email,
      phone: data.phone,
      products: cart.map((item) => ({
        _id: item._id,
        qty: item.qty,
      })),
    };

    const res = await api.post("/order", fd);

    if (res.data.success) {
      navigate("/ordercomplete", {
        state: {
          order: res.data.order,
        },
      });
    } else {
      alert("Something went wrong");
    }

    localStorage.removeItem("cart");
    setCartInReduxAndState();
  };

  useEffect(() => {
    getPayAmount();
  }, [cart]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setCartInReduxAndState();
    getPayAmount();
  }, []);

  return (
    <div>
      <Container>
        <div className="cart_head">
          <Breadcrumb>
            <Breadcrumb.Item href="" onClick={() => navigate("/")}>
              {lng === "en" ? constants.home.en : constants.home.ar}
            </Breadcrumb.Item>

            <Breadcrumb.Item active>
              {lng === "en" ? constants.checkout.en : constants.checkout.ar}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Row>
          <Col md="6">
            <h3 className="cart_sec_title ">
              {lng === "en"
                ? constants.shippingAddress.en
                : constants.shippingAddress.ar}
            </h3>

            <Form className="form_wrap">
              <div className="form_div">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    {lng === "en"
                      ? constants.firstName.en
                      : constants.firstName.ar}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={
                      lng === "en"
                        ? constants.enterFirstName.en
                        : constants.enterFirstName.ar
                    }
                    onChange={(e) =>
                      setData({ ...data, firstName: e.target.value })
                    }
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    {lng === "en"
                      ? constants.lastName.en
                      : constants.lastName.ar}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={
                      lng === "en"
                        ? constants.enterLastName.en
                        : constants.enterLastName.ar
                    }
                    onChange={(e) =>
                      setData({ ...data, lastName: e.target.value })
                    }
                    required
                  />
                </Form.Group>
              </div>
              <div className="form_div">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    {lng === "en" ? constants.address.en : constants.address.ar}
                  </Form.Label>
                  <Form.Control
                    className="mb-3"
                    type="text"
                    placeholder={
                      lng === "en"
                        ? constants.enterAddress.en
                        : constants.enterAddress.ar
                    }
                    onChange={(e) =>
                      setData({ ...data, address: e.target.value })
                    }
                    required
                  />
                  <Form.Control
                    type="text"
                    placeholder={
                      lng === "en"
                        ? constants.enterStreet.en
                        : constants.enterStreet.ar
                    }
                    onChange={(e) =>
                      setData({ ...data, street: e.target.value })
                    }
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    {lng === "en" ? constants.zipcode.en : constants.zipcode.ar}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={
                      lng === "en"
                        ? constants.enterZipcode.en
                        : constants.enterZipcode.ar
                    }
                    onChange={(e) =>
                      setData({ ...data, zipcode: e.target.value })
                    }
                    required
                  />
                </Form.Group>
              </div>
              <div className="form_div">
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>
                    {lng === "en" ? constants.email.en : constants.email.ar}
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder={
                      lng === "en"
                        ? constants.enterEmail.en
                        : constants.enterEmail.ar
                    }
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>
                    {lng === "en" ? constants.phone.en : constants.phone.ar}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={
                      lng === "en"
                        ? constants.enterPhone.en
                        : constants.enterPhone.ar
                    }
                    onChange={(e) =>
                      setData({ ...data, phone: e.target.value })
                    }
                    required
                  />
                </Form.Group>
              </div>
            </Form>
          </Col>
          <Col md="6">
            <h3 className="cart_sec_title ">
              {lng === "en" ? constants.yourOrder.en : constants.yourOrder.ar}
            </h3>

            <div className="checkout_product_wrap">
              {cart &&
                cart.map((item) => (
                  <div className="checkout_product_item">
                    <div className="checkout_product_item_img">
                      <img src={AWS_URL + item.image.image} alt="product" />
                    </div>
                    <div className="checkout_product_item_info">
                      <h4>
                        <Link to={`/product/${item._id}`}>
                          {lng === "en" ? item.nameEn : item.nameAr}
                        </Link>
                      </h4>
                      <div className="checkout_item_price">
                        {item.price} AED
                        <span className="checkout_count">x{item.qty}</span>
                      </div>
                    </div>
                  </div>
                ))}

              <div>
                <div className="order_total">
                  <span className="order_total_title">
                    {lng === "en"
                      ? constants.totalPrice.en
                      : constants.totalPrice.ar}
                    :
                  </span>
                  <span className="order_total_price">
                    {payAmount.toFixed(2)} AED
                  </span>
                </div>
              </div>
            </div>
            <div className="checkout_payment">
              <Button
                className="place_order_button"
                disabled={
                  !cart ||
                  cart.length === 0 ||
                  !data.firstName ||
                  !data.lastName ||
                  !data.address ||
                  !data.zipcode ||
                  !data.email ||
                  !data.phone
                }
                onClick={() => {
                  placeOrder();
                }}
              >
                {lng === "en"
                  ? constants.placeOrder.en
                  : constants.placeOrder.ar}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Checkout;
