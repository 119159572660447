import React, { useEffect } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import bannerImg from "../../assets/img/banner1.png";
import { AWS_URL } from "../../utils/config";
import { getHomeBanners } from "../Redux/Home/actions";

function HomeBanner() {
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.settings.lng);
  const homeBanners = useSelector((state) => state.home.homeBanners);

  useEffect(() => {
    dispatch(getHomeBanners());
  }, []);

  return (
    <div className="home_banner_sec">
      <Container>
        <Row>
          <Col md="12">
            <Carousel>
              {homeBanners.map((banner, index) => (
                <Carousel.Item key={index}>
                  <Link to={banner.link}>
                    <img
                      className="d-block w-100"
                      src={
                        lng === "en"
                          ? AWS_URL + banner.imageEn.image
                          : AWS_URL + banner.imageAr.image
                      }
                      alt={banner.title}
                    />
                  </Link>
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomeBanner;
