import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { BsCheckCircle } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import "./OrderComplete.css";
import { useSelector } from "react-redux";
import { constants } from "./constants";

function OrderComplete() {
  const location = useLocation();
  const lng = useSelector((state) => state.settings.lng);
  const { state } = location;
  const { order } = state;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Container>
        <div className="order_complete_wrap">
          <div className="order_complete_tick">
            <BsCheckCircle />
          </div>
          <h4>{lng === "en" ? constants.message.en : constants.message.ar}.</h4>
          <ul>
            <li>
              {lng === "en"
                ? constants.orderNumber.en
                : constants.orderNumber.ar}
              : <span>{order?.orderId}</span>
            </li>
            <li>
              {lng === "en" ? constants.date.en : constants.date.ar}:{" "}
              <span>
                {moment(order?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
              </span>
            </li>
            <li>
              {lng === "en" ? constants.phone.en : constants.phone.ar}:{" "}
              <span>{order?.phone}</span>
            </li>
            <li>
              {lng === "en" ? constants.email.en : constants.email.ar}:{" "}
              <span>{order?.email}</span>
            </li>
            <li>
              {lng === "en" ? constants.total.en : constants.total.ar}:{" "}
              <span>{order?.total}</span>
            </li>
          </ul>
          <p>
            {lng === "en" ? constants.payWithCash.en : constants.payWithCash.ar}
            .
          </p>
          <Link to="/">
            <Button className="order_complete_button">
              {lng === "en" ? constants.home.en : constants.home.ar}
            </Button>
          </Link>
        </div>
      </Container>
    </div>
  );
}

export default OrderComplete;
