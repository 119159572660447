import React, { useEffect, useState } from "react";
import { Col, Container, Row, Breadcrumb, Button } from "react-bootstrap";
import "./Product.css";
import { AiFillStar } from "react-icons/ai";
import { Link, useNavigate, useParams, Navigate } from "react-router-dom";
import api from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "./constants";
import { AWS_URL } from "../../utils/config";
import { GET_CART } from "../Redux/Shop/constants";

function Product() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lng = useSelector((state) => state.settings.lng);
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [random, setRandom] = useState([]);
  const [cart, setCart] = useState([]);

  const getProduct = async () => {
    const res = await api.get(`/product/?productId=${id}`);
    if (res.data.success) {
      setProduct(res.data.data);
    }
  };

  function addProductToCart(item) {
    let products = [];
    if (localStorage.getItem("cart")) {
      products = JSON.parse(localStorage.getItem("cart"));
    }
    products.push({
      _id: item._id,
      nameEn: item.nameEn,
      nameAr: item.nameAr,
      price: item.price,
      hiddenPrice: item.hiddenPrice,
      image: item.image,
      qty: 1,
    });
    localStorage.setItem("cart", JSON.stringify(products));
    setCart(products);
    setCartInReduxAndState();
  }

  const setCartInReduxAndState = () => {
    let cartItems = localStorage.getItem("cart");
    setCart(JSON.parse(cartItems));
    dispatch({
      type: GET_CART,
      payload: JSON.parse(cartItems),
    });
  };

  const findProductExistInCart = (id) => {
    if (cart) {
      const findProduct = cart.find((product) => product._id === id);

      if (findProduct) {
        return true;
      } else {
        return false;
      }
    }
  };

  const getRandomProducts = async () => {
    const res = await api.get("/product/random");
    if (res.data.success) {
      setRandom(res.data.data);
    }
  };

  const reviewStars = (num) => {
    let stars = [];
    for (let i = 0; i < num; i++) {
      stars.push(<AiFillStar />);
    }
    return <div>{stars}</div>;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getProduct();
    getRandomProducts();
    setCartInReduxAndState();
  }, [id]);

  return (
    <div>
      <Container>
        <div className="product_inner_head">
          <Breadcrumb>
            <Breadcrumb.Item href="" onClick={() => navigate("/")}>
              {lng === "en" ? constants.home.en : constants.home.ar}
            </Breadcrumb.Item>

            <Breadcrumb.Item active>
              {lng === "en" ? constants.product.en : constants.product.ar}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="product_inner_wrapper">
          <Row>
            <Col md="6">
              <div className="product_inner_img">
                <img
                  src={product.image ? AWS_URL + product.image.image : ""}
                  alt={product.nameEn}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="product_inner_info">
                <h2>{lng === "en" ? product.nameEn : product.nameAr}</h2>
                <div className="product_inner_rating">
                  {reviewStars(product.star)}
                </div>
                <div className="product_inner_price">
                  <p className="product_inner_original_price">
                    {product.hiddenPrice} AED
                  </p>
                  <p className="product_inner_offer_price">
                    {product.price} AED
                  </p>
                </div>
                <p className="product_inner_description">
                  {lng === "en" ? product.descriptionEn : product.descriptionAr}
                </p>
                <div>
                  <Button
                    className="product_inner_add_to_cart_btn"
                    onClick={() => addProductToCart(product)}
                    disabled={findProductExistInCart(product._id)}
                  >
                    {findProductExistInCart(product._id)
                      ? lng === "en"
                        ? constants.addedToCart.en
                        : constants.addedToCart.ar
                      : lng === "en"
                      ? constants.addCart.en
                      : constants.addCart.ar}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="shop_items_wrapper">
          <h3 className="hom_sec_title">
            {lng === "en" ? constants.like.en : constants.like.ar}
          </h3>

          <Row>
            {random.map((item) => (
              <Col md="3" sm="6" xs="6">
                <div className="shop_item_card">
                  <div className="shop_item_card_img">
                    <Link to={`/product/${item._id}`}>
                      <img src={AWS_URL + item.image.image} alt="product" />
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={`/product/${item._id}`}
                      className="shop_prod_title"
                    >
                      {" "}
                      <h4>{lng === "en" ? item.nameEn : item.nameAr}</h4>{" "}
                    </Link>
                    <div className="shop_prod_rating">
                      {reviewStars(item.star)}
                    </div>
                    <div className="shop_prod_price">
                      <p className="shop_prod_original_price">
                        {item.hiddenPrice} AED
                      </p>
                      <p className="shop_prod_offer_price">{item.price} AED</p>
                    </div>
                    <Button
                      className="shop_prod_add_to_cart_btn"
                      onClick={() => addProductToCart(item)}
                      disabled={findProductExistInCart(item._id)}
                    >
                      {findProductExistInCart(item._id)
                        ? lng === "en"
                          ? constants.addedToCart.en
                          : constants.addedToCart.ar
                        : lng === "en"
                        ? constants.addCart.en
                        : constants.addCart.ar}
                    </Button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default Product;
