import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import { FaQuoteLeft } from "react-icons/fa";
import "./Testimonial.css";
import { useDispatch, useSelector } from "react-redux";
import { getTestimonials } from "../../Redux/About/actions";
import { AWS_URL } from "../../../utils/config";

const options = {
  loop: true,
  nav: false,
  dots: false,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  },
};

function Testimonial() {
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.settings.lng);
  const testimonials = useSelector((state) => state.about.testimonials);

  useEffect(() => {
    dispatch(getTestimonials());
  }, []);

  return (
    <>
      <OwlCarousel className="owl-theme" {...options} autoplay>
        {testimonials.map((item) => (
          <div className="item">
            <div className="testimonial_item">
              <span>
                <FaQuoteLeft />
              </span>
              <p className="testimonial_item_content">
                {lng === "en" ? item.messageEn : item.messageAr}
              </p>
              <hr></hr>
              <div className="testimonial_info">
                <div className="testimonial_info_img">
                  <img src={AWS_URL + item.image.image} alt={item.nameEn} />
                </div>
                <div className="testimonial_info_details">
                  <h4>{lng === "en" ? item.nameEn : item.nameAr}</h4>
                </div>
              </div>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </>
  );
}

export default Testimonial;
