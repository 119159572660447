import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiFillStar } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { AWS_URL } from "../../utils/config";
import { getHomeProducts } from "../Redux/Home/actions";
import { constants } from "./constants";

const FeaturedProducts = () => {
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.settings.lng);
  const products = useSelector((state) => state.home.products);

  const reviewStars = (num) => {
    let stars = [];
    for (let i = 0; i < num; i++) {
      stars.push(<AiFillStar />);
    }

    return <div>{stars}</div>;
  };

  useEffect(() => {
    dispatch(getHomeProducts());
  }, []);

  return (
    <>
      <div className="hom_sec">
        <Container>
          <Row>
            <h3 className="hom_sec_title">
              {lng === "en"
                ? constants.feturedHead.en
                : constants.feturedHead.ar}
            </h3>
            {products.map((item) => (
              <Col md="4">
                <div className="home-prod-item">
                  <div className="home-prod-img">
                    <Link to={`/product/${item._id}`}>
                      <img src={AWS_URL + item.image.image} alt={item.nameEn} />
                    </Link>
                  </div>
                  <div className="home-prod-info">
                    <Link
                      to={`/product/${item._id}`}
                      className="home_prod_title"
                    >
                      {lng === "en" ? item.nameEn : item.nameAr}
                    </Link>
                    <div>{reviewStars(item.star)}</div>
                    <p className="home_prod_price">{item.price} AED</p>
                    <Link
                      to={`/product/${item._id}`}
                      className="home_prod_button"
                    >
                      Shop Now
                    </Link>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default FeaturedProducts;
