import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AWS_URL } from "../../utils/config";
import { getBestSellers } from "../Redux/Home/actions";

const HomeBestSellers = () => {
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.settings.lng);
  const bestSellers = useSelector((state) => state.home.bestSellers);

  useEffect(() => {
    dispatch(getBestSellers());
  }, []);

  return (
    <>
      <div className="home-ad-section">
        <Container>
          <Row>
            {bestSellers.map((item) => (
              <Col md="4">
                <div className="home-ads-items">
                  <div className="home-ads-items-content">
                    <Link to={item.link}>
                      <img
                        src={
                          lng === "en"
                            ? AWS_URL + item.imageEn.image
                            : AWS_URL + item.imageAr.image
                        }
                        alt={item.title}
                      />
                    </Link>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HomeBestSellers;
