export const constants = {
  home: {
    en: "Home",
    ar: "الصفحة الرئيسية",
  },
  about: {
    en: "About",
    ar: "من نحن",
  },
  contact: {
    en: "Contact",
    ar: "اتصل بنا",
  },
  shop: {
    en: "Shop",
    ar: "المتجر",
  },
  exclusives: {
    en: "Exclusives",
    ar: "المحددات",
  },
  perfumes: {
    en: "Perfumes",
    ar: "الحلاقات",
  },
  perfumeOils: {
    en: "Perfume Oils",
    ar: "أنابيب الحلاقات",
  },
  homeFragrance: {
    en: "Home Fragrance",
    ar: "الحلاقات المنزلية",
  },
  bestSellers: {
    en: "Best Sellers",
    ar: "الأكثر مبيعا",
  },
};
