import api from "../../../utils/api";
import {
  GET_BEST_SELLER,
  GET_HOME_BANNERS,
  GET_HOME_DEALS,
  GET_HOME_OFF,
  GET_HOME_PRODUCTS,
  GET_INSTA_FEEDS,
} from "./constants";

// Get home banners
export const getHomeBanners = () => async (dispatch, getState) => {
  try {
    const res = await api.get("/home/home-banner");

    if (res.data.success) {
      dispatch({
        type: GET_HOME_BANNERS,
        payload: res.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Get home best sellers
export const getBestSellers = () => async (dispatch, getState) => {
  try {
    const res = await api.get("/home/best-seller");

    if (res.data.success) {
      dispatch({
        type: GET_BEST_SELLER,
        payload: res.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Get home off
export const getHomeOff = () => async (dispatch, getState) => {
  try {
    const res = await api.get("/home/offer-banner");

    if (res.data.success) {
      dispatch({
        type: GET_HOME_OFF,
        payload: res.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Get home deals
export const getHomeDeals = () => async (dispatch, getState) => {
  try {
    const res = await api.get("/home/deal-banner");

    if (res.data.success) {
      dispatch({
        type: GET_HOME_DEALS,
        payload: res.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Get insta feeds
export const getInstaFeeds = () => async (dispatch, getState) => {
  try {
    const res = await api.get("/home/instagram");

    if (res.data.success) {
      dispatch({
        type: GET_INSTA_FEEDS,
        payload: res.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Get home products
export const getHomeProducts = () => async (dispatch, getState) => {
  try {
    const res = await api.get("/home/products");

    if (res.data.success) {
      dispatch({
        type: GET_HOME_PRODUCTS,
        payload: res.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};
