import React, { useEffect, useState } from "react";
import {
  Container,
  Navbar,
  Nav,
  Dropdown,
  Button,
  Form,
} from "react-bootstrap";
import { AiOutlineShoppingCart } from "react-icons/ai";
import "./Header.css";
import { Link, NavLink } from "react-router-dom";
import useLocalStorage from "react-use-localstorage";
import { useDispatch, useSelector } from "react-redux";
import { GET_LNG } from "../../Redux/Settings/constants";
import { constants } from "./constants";
import { getCommon } from "../../Redux/Settings/actions";
import { BsInstagram, BsWhatsapp } from "react-icons/bs";
import { FiFacebook } from "react-icons/fi";

function Header() {
  const dispatch = useDispatch();
  const lange = useSelector((state) => state.settings.lng);
  const common = useSelector((state) => state.settings.common);
  const cart = useSelector((state) => state.shop.cart);

  let lang = localStorage.getItem("lng");
  const [lng, setLng] = useLocalStorage("lng", lang ? lang : "en");

  useEffect(() => {
    dispatch({ type: GET_LNG, payload: lng });
  }, [lng]);

  useEffect(() => {
    dispatch(getCommon());
  }, []);

  return (
    <div>
      <div className="title-bar">
        <Container>
          <div className="title-bar-child">
            <ul>
              <li>
                <a
                  href={common.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FiFacebook />
                </a>
              </li>
              <li>
                <a
                  href={common.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsInstagram />
                </a>
              </li>
              <li>
                <a
                  href={common.whatsapp}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsWhatsapp />
                </a>
              </li>
            </ul>
            <div>
              <Form.Group className="">
                <Form.Select onChange={(e) => setLng(e.target.value)}>
                  <option value="en" selected={lang === "en"}>
                    English
                  </option>
                  <option value="ar" selected={lang === "ar"}>
                    عربي
                  </option>
                </Form.Select>
              </Form.Group>
            </div>
          </div>
        </Container>
      </div>
      <Navbar expand="lg">
        <Container>
          <Link to="/">
            {/* <Navbar.Brand> */}
            <div className="header_logo">
              <img src="/assets/img/headerlogo.png" />
            </div>
            {/* </Navbar.Brand> */}
          </Link>
          {/* cart desktop */}
          <div className="header-cart-desk">
            <NavLink to="/cart">
              <Button className="header-cart" href="#link">
                <AiOutlineShoppingCart />
                <span className="cart_count_header">
                  {cart ? cart.length : 0}
                </span>
              </Button>
            </NavLink>
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavLink className="nav-menu-items nav-link" to="/">
                {lange === "en" ? constants.home.en : constants.home.ar}
              </NavLink>
              <NavLink className="nav-menu-items nav-link" to="/about">
                {lange === "en" ? constants.about.en : constants.about.ar}
              </NavLink>
              <NavLink className="nav-menu-items nav-link" to="/contact">
                {lange === "en" ? constants.contact.en : constants.contact.ar}
              </NavLink>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {lange === "en" ? constants.shop.en : constants.shop.ar}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <NavLink to="/shop/exclusive">
                    {lange === "en"
                      ? constants.exclusives.en
                      : constants.exclusives.ar}
                  </NavLink>
                  <NavLink to="/shop/perfume">
                    {lange === "en"
                      ? constants.perfumes.en
                      : constants.perfumes.ar}
                  </NavLink>
                  <NavLink to="/shop/perfume-oil">
                    {lange === "en"
                      ? constants.perfumeOils.en
                      : constants.perfumeOils.ar}
                  </NavLink>
                  <NavLink to="/shop/home-fragrance">
                    {lange === "en"
                      ? constants.homeFragrance.en
                      : constants.homeFragrance.ar}
                  </NavLink>
                  <NavLink to="/shop/best-seller">
                    {lange === "en"
                      ? constants.bestSellers.en
                      : constants.bestSellers.ar}
                  </NavLink>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
          {/* mobile cart */}
          <div className="header-cart-resp">
            <Link to="/cart">
              <Button className="header-cart">
                <AiOutlineShoppingCart />
                <span className="cart_count_header">
                  {cart ? cart.length : 0}
                </span>
              </Button>
            </Link>
          </div>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
