import {
  GET_BEST_SELLER,
  GET_HOME_BANNERS,
  GET_HOME_DEALS,
  GET_HOME_OFF,
  GET_HOME_PRODUCTS,
  GET_INSTA_FEEDS,
} from "./constants";

const initialState = {
  loading: false,
  homeBanners: [],
  bestSellers: [],
  homeOff: {},
  homeDeals: [],
  instaFeeds: [],
  products: [],
};

export default function home(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_HOME_BANNERS:
      return {
        ...state,
        loading: false,
        homeBanners: payload.data,
      };

    case GET_BEST_SELLER:
      return {
        ...state,
        loading: false,
        bestSellers: payload.data,
      };

    case GET_HOME_OFF:
      return {
        ...state,
        loading: false,
        homeOff: payload.data[0],
      };

    case GET_HOME_DEALS:
      return {
        ...state,
        loading: false,
        homeDeals: payload.data,
      };

    case GET_INSTA_FEEDS:
      return {
        ...state,
        loading: false,
        instaFeeds: payload.data,
      };

    case GET_HOME_PRODUCTS:
      return {
        ...state,
        loading: false,
        products: payload.data,
      };

    default:
      return state;
  }
}
