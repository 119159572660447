import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./Cart.css";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "./constants";
import { GET_CART } from "../Redux/Shop/constants";
import { AWS_URL } from "../../utils/config";

function Cart() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.settings.lng);
  const [cart, setCart] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [payAmount, setPayAmount] = useState(0);

  const setCartInReduxAndState = () => {
    let cartItems = localStorage.getItem("cart");
    setCart(JSON.parse(cartItems));
    dispatch({
      type: GET_CART,
      payload: JSON.parse(cartItems),
    });
  };

  const increaseQtyOfProduct = (product) => {
    let cartItems = localStorage.getItem("cart");
    let cartItemsArray = JSON.parse(cartItems);
    let index = cartItemsArray.findIndex((item) => item._id == product._id);
    cartItemsArray[index].qty = cartItemsArray[index].qty + 1;
    localStorage.setItem("cart", JSON.stringify(cartItemsArray));
    setCartInReduxAndState();
  };

  const decreaseQtyOfProduct = (product) => {
    let cartItems = localStorage.getItem("cart");
    let cartItemsArray = JSON.parse(cartItems);
    let index = cartItemsArray.findIndex((item) => item._id == product._id);
    if (cartItemsArray[index].qty > 1) {
      cartItemsArray[index].qty = cartItemsArray[index].qty - 1;
      localStorage.setItem("cart", JSON.stringify(cartItemsArray));
      setCartInReduxAndState();
    } else {
      cartItemsArray.splice(index, 1);
      localStorage.setItem("cart", JSON.stringify(cartItemsArray));
      setCartInReduxAndState();
    }
  };

  const getTotalAmount = () => {
    let amount = 0;
    if (cart) {
      cart.map((item) => {
        amount = amount + item.hiddenPrice * item.qty;
      });
    }
    setTotalAmount(amount);
  };

  const getDiscountAmount = () => {
    let amount = 0;
    if (cart) {
      cart.map((item) => {
        amount = amount + (item.hiddenPrice - item.price) * item.qty;
      });
    }
    setDiscountAmount(amount);
  };

  const getPayAmount = () => {
    let amount = 0;
    if (cart) {
      cart.map((item) => {
        amount = amount + item.price * item.qty;
      });
    }
    setPayAmount(amount);
  };

  useEffect(() => {
    getTotalAmount();
    getDiscountAmount();
    getPayAmount();
  }, [cart]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setCartInReduxAndState();
    getTotalAmount();
    getDiscountAmount();
    getPayAmount();
  }, []);

  return (
    <div className="">
      <Container>
        <div className="cart_head">
          <Breadcrumb>
            <Breadcrumb.Item href="" onClick={() => navigate("/")}>
              {lng === "en" ? constants.home.en : constants.home.ar}
            </Breadcrumb.Item>

            <Breadcrumb.Item active>
              {lng === "en" ? constants.cart.en : constants.cart.ar}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Row>
          <Col md="6">
            <h3 className="cart_head_title">
              {lng === "en" ? constants.shopBag.en : constants.shopBag.ar}
            </h3>
            {cart && cart.length > 0 ? (
              <div className="cart_table">
                <div className="cart_list_head">
                  <h5>
                    {lng === "en" ? constants.cart.en : constants.cart.ar}
                  </h5>
                  <h5>{payAmount.toFixed(2)} AED</h5>
                </div>
                {cart.map((product) => (
                  <div className="cart_table_list">
                    <div className="cart_table_list_img">
                      <div className="cart_table_list_img_wrap">
                        <img
                          src={AWS_URL + product.image.image}
                          alt="product"
                        />
                      </div>
                    </div>
                    <div className="cart_table_list_info">
                      <h4>{lng === "en" ? product.nameEn : product.nameAr}</h4>
                      <div className="cart_table_list_info_price">
                        <span>{product.hiddenPrice} AED</span>
                        {product.price} AED
                      </div>
                      <div className="cart_table_list_info_qty">
                        <Button
                          variant="outline-secondary"
                          id="button-addon1"
                          onClick={() => decreaseQtyOfProduct(product)}
                        >
                          <AiOutlineMinus />
                        </Button>
                        <div>{product.qty}</div>

                        <Button
                          variant="outline-secondary"
                          id="button-addon1"
                          onClick={() => increaseQtyOfProduct(product, 1)}
                        >
                          {" "}
                          <AiOutlinePlus />
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="cart_empty">
                <h3>
                  {lng === "en" ? constants.empty.en : constants.empty.ar}
                </h3>
                <Link to="/shop/best-seller">
                  <Button className="cart_btn">
                    {lng === "en" ? constants.shop.en : constants.shop.ar}
                  </Button>
                </Link>
              </div>
            )}
          </Col>
          <Col md="4">
            <h3 className="cart_head_title">
              {lng === "en"
                ? constants.paymentDetails.en
                : constants.paymentDetails.ar}
            </h3>
            <div className="cart_wrap">
              <div className="cart_mrp_total">
                <div className="cart_mrp_total_label">
                  {lng === "en" ? constants.total.en : constants.total.ar}
                </div>
                <div className="cart_mrp_total_price">
                  {totalAmount.toFixed(2)} AED
                </div>
              </div>
              <div className="cart_mrp_total">
                <div className="cart_mrp_total_label">
                  {lng === "en" ? constants.discount.en : constants.discount.ar}
                </div>
                <div className="cart_mrp_total_price">
                  {" "}
                  - {discountAmount.toFixed(2)} AED
                </div>
              </div>
              <div className="cart_total_amount">
                <div className="cart_total_amount_label">
                  {lng === "en" ? constants.pay.en : constants.pay.ar}
                </div>
                <div className="cart_total_amount_price">
                  {payAmount.toFixed(2)} AED
                </div>
              </div>
              <div>
                <Button
                  className="cart_btn"
                  disabled={!cart || cart.length === 0}
                  onClick={() => {
                    navigate("/checkout");
                  }}
                >
                  {lng === "en" ? constants.proceed.en : constants.proceed.ar}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Cart;
