import React, { useEffect } from "react";
import "./Home.css";
import HomeBanner from "./HomeBanner";
import HomeInstaSlider from "./HomeInstaSlider";
import HomeBestSellers from "./HomeBestSellers";
import HomeOff from "./HomeOff";
import FeaturedProducts from "./FeaturedProducts";
import HomeDeal from "./HomeDeal";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <HomeBanner />
      <HomeBestSellers />
      <HomeOff />
      <FeaturedProducts />
      <HomeDeal />
      <HomeInstaSlider />
    </div>
  );
}

export default Home;
