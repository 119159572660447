import { useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { HashRouter, Routes, Route } from "react-router-dom";
import Header from "./components/common/header/Header";
import Home from "./components/home/Home";
import Product from "./components/product/Product";
import Cart from "./components/cart/Cart";
import Checkout from "./components/checkout/Checkout";
import OrderComplete from "./components/orderComplete/OrderComplete";
import About from "./components/about/about/About";
import Contact from "./components/contact/Contact";
import Footer from "./components/common/footer/Footer";
import Exclusive from "./components/shop/exclusive/Exclusive";
import Perfume from "./components/shop/perfume/Perfume";
import PerfumeOil from "./components/shop/perfumeOil/PerfumeOil";
import Fragrance from "./components/shop/fragrance/Fragrance";
import BestSeller from "./components/shop/bestSeller/BestSeller";
import { useDispatch } from "react-redux";
import { GET_LNG } from "./components/Redux/Settings/constants";
import { GET_CART } from "./components/Redux/Shop/constants";

function App() {
  const dispatch = useDispatch();

  const langHandler = () => {
    var lang = localStorage.getItem("lng");
    if (!lang) {
      localStorage.setItem("lng", "en");
      lang = "en";
    }
    dispatch({ type: GET_LNG, payload: lang ? lang : "en" });
  };

  const setCartInReduxAndState = () => {
    let cartItems = localStorage.getItem("cart");
    dispatch({
      type: GET_CART,
      payload: JSON.parse(cartItems),
    });
  };

  useEffect(() => {
    langHandler();
    setCartInReduxAndState();
  }, []);

  return (
    <div className="App">
      <HashRouter>
        <Header />
        <Routes>
          <Route path="/shop/exclusive" element={<Exclusive />} />
          <Route path="/shop/perfume" element={<Perfume />} />
          <Route path="/shop/perfume-oil" element={<PerfumeOil />} />
          <Route path="/shop/home-fragrance" element={<Fragrance />} />
          <Route path="/shop/best-seller" element={<BestSeller />} />

          <Route path="/checkout" element={<Checkout />} />
          <Route path="/ordercomplete" element={<OrderComplete />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />

          <Route path="product/:id" element={<Product />} />
          <Route path="/cart" element={<Cart />} />

          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
