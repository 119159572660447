import api from "../../../utils/api";
import { GET_COMMON } from "./constants";

// Get common
export const getCommon = () => async (dispatch, getState) => {
  try {
    const res = await api.get("/common");

    if (res.data.success) {
      dispatch({
        type: GET_COMMON,
        payload: res.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};
