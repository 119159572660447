import React, { useState } from "react";
import { Col, Container, Form, Row, Breadcrumb, Button } from "react-bootstrap";
import "./Contact.css";
import { IoLocationSharp } from "react-icons/io5";
import { BsFillTelephoneFill } from "react-icons/bs";
import { BiEnvelope } from "react-icons/bi";
import Iframe from "react-iframe";
import { FaWhatsapp } from "react-icons/fa";
import { AiOutlineInstagram, AiOutlineFacebook } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { constants } from "./constants";
import api from "../../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  const navigate = useNavigate();
  const lng = useSelector((state) => state.settings.lng);
  const common = useSelector((state) => state.settings.common);
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    content: "",
  });

  const submitHandler = async (e) => {
    e.preventDefault();
    let fd = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      subject: data.subject,
      content: data.content,
    };
    try {
      const res = await api.post("/common/form", fd);
      if (res.data.success) {
        toast.success(res.data.message);
      }
    } catch (error) {
      console.log(error.response);
      toast.error(error?.response?.data?.message?.error);
    }
    setData({
      name: "",
      email: "",
      phone: "",
      subject: "",
      content: "",
    });
    e.target.reset();
  };

  return (
    <div>
      <Container>
        <div className="cart_head">
          <Breadcrumb>
            <Breadcrumb.Item href="" onClick={() => navigate("/")}>
              {lng === "en" ? constants.home.en : constants.home.ar}
            </Breadcrumb.Item>

            <Breadcrumb.Item active>
              {lng === "en" ? constants.contact.en : constants.contact.ar}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </Container>
      <div className="contact_map">
        <Iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d116277.04374741971!2d54.383760617880995!3d24.39321129787772!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e41f4699268bd%3A0x1d5bd425d5d1e64c!2sOUD%20MASHHOOR%20PERFUMES!5e0!3m2!1sen!2sin!4v1647064204793!5m2!1sen!2sin"
          width="600"
          height="450"
          style="border:0;"
          allowfullscreen=""
          loading="lazy"
        ></Iframe>
      </div>
      <div>
        <Container>
          <div className="contact_parent">
            <Row className="contact_row">
              <Col md="8" className="contact_form_wrap">
                <h4>{lng === "en" ? constants.send.en : constants.send.ar} </h4>

                <Form onSubmit={submitHandler}>
                  <div className="form_div">
                    <Form.Group
                      className="mb-3 form_div_child"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>
                        {lng === "en" ? constants.name.en : constants.name.ar}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        onChange={(e) =>
                          setData({ ...data, name: e.target.value })
                        }
                        required
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3 form_div_child"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>
                        {lng === "en" ? constants.email.en : constants.email.ar}
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder=""
                        onChange={(e) =>
                          setData({ ...data, email: e.target.value })
                        }
                        required
                      />
                    </Form.Group>
                  </div>
                  <div className="form_div">
                    <Form.Group
                      className="mb-3 form_div_child"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>
                        {lng === "en" ? constants.phone.en : constants.phone.ar}
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder=""
                        onChange={(e) =>
                          setData({ ...data, phone: e.target.value })
                        }
                        required
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3 form_div_child"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>
                        {lng === "en"
                          ? constants.subject.en
                          : constants.subject.ar}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        onChange={(e) =>
                          setData({ ...data, subject: e.target.value })
                        }
                        required
                      />
                    </Form.Group>
                  </div>
                  <div className="form_div_textarea form_div">
                    <Form.Group
                      className="mb-3 form_div_child_textarea"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>
                        {" "}
                        {lng === "en"
                          ? constants.message.en
                          : constants.message.ar}{" "}
                      </Form.Label>
                      <Form.Control
                        type="textarea"
                        placeholder=""
                        style={{ height: "100px" }}
                        onChange={(e) =>
                          setData({ ...data, content: e.target.value })
                        }
                        required
                      />
                    </Form.Group>
                  </div>
                  <div>
                    <Button className="contact_form_btn" type="submit">
                      {lng === "en"
                        ? constants.sendMsg.en
                        : constants.sendMsg.ar}
                    </Button>
                  </div>
                </Form>
              </Col>
              <Col md="4" className="contact_item_wrap_parent">
                <div className="contact_item_wrap">
                  <div className="">
                    <h4>
                      {lng === "en"
                        ? constants.contactInfo.en
                        : constants.contactInfo.ar}
                    </h4>
                    <ul className="contact_item_wrapper">
                      <li className="contact_item">
                        <div>
                          <div className="contact_item_icon">
                            <IoLocationSharp />
                          </div>
                        </div>

                        <div className="contact_item_content">
                          {lng === "en" ? common.addressEn : common.addressAr}
                        </div>
                      </li>
                      <li className="contact_item">
                        <div>
                          <div className="contact_item_icon">
                            <BsFillTelephoneFill />
                          </div>
                        </div>

                        <div className="contact_item_content">
                          {common.phone}
                        </div>
                      </li>
                      <li className="contact_item">
                        <div>
                          <div className="contact_item_icon">
                            <BiEnvelope />
                          </div>
                        </div>

                        <div className="contact_item_content">
                          {common.email}
                        </div>
                      </li>
                    </ul>
                  </div>
                  <ul className="contact_social_wrap">
                    <li>
                      <a
                        href={common.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AiOutlineFacebook />
                      </a>
                    </li>
                    <li>
                      <a
                        href={common.instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AiOutlineInstagram />
                      </a>
                    </li>
                    <li>
                      <a
                        href={common.whatsapp}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaWhatsapp />
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Contact;
