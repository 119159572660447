import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import { BsInstagram } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "./constants";
import { getInstaFeeds } from "../Redux/Home/actions";
import { AWS_URL } from "../../utils/config";

const options = {
  loop: true,
  nav: false,
  dots: false,
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 5,
    },
  },
};

function HomeInstaSlider() {
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.settings.lng);
  const instaFeeds = useSelector((state) => state.home.instaFeeds);

  useEffect(() => {
    dispatch(getInstaFeeds());
  }, []);

  return (
    <>
      {instaFeeds.length > 0 && (
        <div className="hom_inst_sec">
          <div className="hom_insta_head_icon">
            <BsInstagram />
          </div>
          <h3 className="hom_sec_title">
            {lng === "en" ? constants.instaHead.en : constants.instaHead.ar}
          </h3>
          <div>
            <OwlCarousel className="owl-theme" {...options} autoplay>
              {instaFeeds.map((post) => (
                <div className="item">
                  <div className="insta_item">
                    <div className="insta_item_img">
                      <img src={AWS_URL + post.image.image} alt={post.title} />
                    </div>
                    <span className="insta_item_icon">
                      <BsInstagram />
                    </span>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      )}
    </>
  );
}

export default HomeInstaSlider;
