import { GET_COMMON, GET_LNG } from "./constants";

const initialState = {
  loading: false,
  lng: "en",
  common: {},
  error: {},
};

export default function settings(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_LNG:
      return {
        ...state,
        loading: false,
        lng: payload,
      };

    case GET_COMMON:
      return {
        ...state,
        loading: false,
        common: payload.data,
      };

    default:
      return state;
  }
}
