import { combineReducers } from "redux";
import settings from "./components/Redux/Settings/reducer";
import home from "./components/Redux/Home/reducer";
import about from "./components/Redux/About/reducer";
import shop from "./components/Redux/Shop/reducer";

export default combineReducers({
  settings,
  home,
  about,
  shop,
});
