export const constants = {
  home: {
    en: "Home",
    ar: "الرئيسية",
  },
  cart: {
    en: "Cart",
    ar: "عربة التسوق",
  },
  shopBag: {
    en: "Shopping Bag",
    ar: "عربة التسوق",
  },
  paymentDetails: {
    en: "Payment Details",
    ar: "تفاصيل الدفع",
  },
  total: {
    en: "Total",
    ar: "المجموع",
  },
  discount: {
    en: "Discount",
    ar: "الخصم",
  },
  pay: {
    en: "Pay",
    ar: "الدفع",
  },
  proceed: {
    en: "Proceed to Checkout",
    ar: "الذهاب للتحقق",
  },
  empty: {
    en: "Your cart is empty",
    ar: "عربة التسوق فارغة",
  },
  shop: {
    en: "Shop",
    ar: "المتجر",
  },
};
